import React, { useEffect } from "react";

const SidebarWidget = () => {
  useEffect(() => {
    const widgetContainer = document.getElementById("sidebar-widget-js");
    const widgetScript = document.createElement("script");
    widgetScript.setAttribute("type", "text/javascript");
    widgetScript.innerHTML = `!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');`;
    widgetContainer.appendChild(widgetScript);
  }, []);
  return (
    <div className="sidebar-widget" id="sidebar-widget-js">
      <a
        class="weatherwidget-io"
        href="https://forecast7.com/es/40d46n3d75/spain/"
        data-label_1="SPAIN"
        data-label_2="WEATHER"
        data-font="Roboto"
        data-icons="Climacons"
        data-theme="pure"
      >
        SPAIN WEATHER
      </a>
    </div>
  );
};

export default SidebarWidget;
